<template>
  <div class="paginated-cardgroup">

    <b-card-group id="card-group" class="xcontainer" deck>
      <template v-for="item in wares">
        <b-card class="card" :key="item.id" @click="$emit('card-click', item)">
          <b-row class="row">
            <b-card-img class="card-img" :src="`/api/public/wares/${item.src}`"></b-card-img>
          </b-row>
          <b-row>
            <b-card-body :title="item.title" :sub-title="item.price" style="margin: 0.8rem;">
              <b-card-text>{{item.description}}</b-card-text>
            </b-card-body>
          </b-row>
          <div class="overlay"></div>
          <b-button class="overlay-button" :variant="btnVariant" @click="$emit('button-click', item)">{{btnText}}</b-button>
        </b-card>
      </template>
    </b-card-group>

    <b-pagination
      v-show="wareCount > perPage"
      class="page-navbar"
      v-model="currentPage"
      :total-rows="wareCount"
      :per-page="perPage"
      @input="fetchWares"
    ></b-pagination>
  
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: 'PaginatedCardGroup',
  data() {
    return {
      currentPage: 1
    }
  },
  props: {
    type: String,
    btnVariant: String,
    btnText: String
  },
  computed: {
    ...mapGetters({ perPage: "waresPerPage" }),

    wares() { return this.$store.getters.wares[this.type] },
    wareCount() { return this.$store.getters.wareCount[this.type] },
  },
  methods: {
    fetchWares: function(page) {
        const offset = this.perPage * (page-1);
        this.$store.dispatch('fetchWares', { limit: this.perPage, offset: offset, type: this.type });
    },
  },
  created: function() {
    this.fetchWares(1);
    this.$store.dispatch('fetchWareCount', { type: this.type });
  }
}
</script>

<style lang="scss" scoped>

  .overlay, .overlay-button {
    position: absolute;
    opacity: 0;
    transition: .2s ease;
  }

  .overlay {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background-color: black;
  }

  .overlay-button {
    text-align: center;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
  }

  .card:hover .overlay {
    opacity: .7;
  }
  .card:hover .overlay-button {
    opacity: 1;
  }

  .card {
    max-width: 240px;
    min-width: 240px;
    height: 23rem;
    overflow: hidden;
    cursor: default;
  }

  .card-img {
    object-fit: cover;
  }

  .row {
    margin: 0;
    height: 200px;
    overflow: hidden;
  }

  .page-navbar {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
    justify-content: center;
  }

  .card-body {
    padding: 0;
  }

  .card-deck .card {
    margin-bottom: 1em;
  }

  .card-deck {
    margin: 0;
  }

  #card-group {
    padding-bottom: 1em;
    margin: 0;
    flex-flow: wrap;
  }
  
</style>