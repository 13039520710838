<template>
  <div class="warepage">
    <div class="title-div" :class="$mq" style="background: var(--primary-color);">
      <h1 class="wareType" :class="$mq">{{title}}</h1>
    </div>
    
    <div class="divider divider-waves" :class="$mq"></div>

    <br>

    <div class="xcontainer">
      <PaginatedWareCardGroup 
        class="ware-group"
        :type="waretype" 
        :btnText="!isLoggedIn ? 'Kontakt os' : 'Delete'" 
        :btnVariant="!isLoggedIn ? 'success' : 'danger'" 
        @button-click="isLoggedIn ? deleteWare($event) : $router.push('/about')"
      >
      </PaginatedWareCardGroup>
    </div>

    <div v-if="isLoggedIn" v-show="showForm">
      <b-form id="postForm" @submit.prevent="addWare">
        <b-icon id="post-form-close" icon="x" aria-hidden="true" @click="showForm = !showForm"></b-icon>
        <h4 style="color: var(--light-primary-color);">Add Item</h4>
        <b-form-input v-model="form.title" placeholder="Title" required></b-form-input>
        <b-form-input class="mt-2" v-model="form.description" placeholder="Description (optional)"></b-form-input>
        <b-form-input v-model="form.price" placeholder="Price" required></b-form-input>
        <b-form-file class="mt-2" v-model="form.src" :state="Boolean(form.src)" placeholder="Choose an image or drop it here..." drop-placeholder="Drop image here..."
        accept="image/jpeg, image/png"/>
        <b-button class="mt-3" type="submit" :disabled="form.title.length === 0 || form.price.length === 0 || form.src === null" variant="primary">Submit</b-button>
      </b-form>
      <div class="b-sidebar-backdrop bg-dark" @click="showForm = !showForm"></div>
    </div>
    
    <b-icon v-if="isLoggedIn" id="add-item-button" icon="plus-circle-fill" aria-hidden="true" @click="showForm = !showForm"></b-icon>
  
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import PaginatedWareCardGroup from '@/components/PaginatedWareCardGroup';

export default {
  name: 'WarePage',
  props: {
    waretype: String
  },
  components: {
    PaginatedWareCardGroup
  },
  data() {
    return {
      form: {
        title: '',
        description: '',
        price: '',
        src: null
      },
      showForm: false
    }
  },
  computed: {
    ...mapGetters({ isLoggedIn: "isLoggedIn" }),

    title() { return this.$store.getters.translations[this.waretype] }
  },
  methods: {
    addWare: function () {
      this.$store.dispatch('addWare', { ware: this.form, type: this.waretype }).then(() => {
        this.form = {
          title: '',
          description: '',
          price: '',
          src: null
        };
        this.showForm = false;
      }).catch(err => {
        console.log(err);
      });
    },

    deleteWare: function (ware) {
          this.$store.dispatch('deleteWare', { ware, type: this.waretype }).catch(err => {
              console.log(err);
          });
    }
  }
}
</script>

<style lang="scss">
  .warepage {
    background: var(--light-primary-color);
  }

  .title-div {
    color: var(--light-primary-color);
  }

  .wareType {
    padding-top: 3.5em;
    padding-bottom: 1em;
    display: block;
    text-align: center;
    margin: 0;
    font-family: 'Red Hat Text', sans-serif;
    font-weight: 500;
  }

  .wareType.desktop {
    font-size: 2.6rem;
  }

  .ware-group {
    max-width: 60em;
    padding-bottom: 4em;
  }

  #postForm {
    position: fixed;
    top: 20em;
    left: 50%;
    transform: translateX(-50%);
    background: var(--primary-color);
    padding: 3em;
    z-index: 10000;
    box-shadow: 0 0 8px var(--primary-text);
  }

  #post-form-close {
    position: absolute;
    width: 2.5em;
    height: 2.5em;
    right: 1em;
    top: 1em;
    color: var(--light-primary-color);
  }

  #add-item-button {
    position: fixed;
    right: 5vw;
    bottom: 5vw;
    font-size: 2em;
    color: var(--primary-color);
    border-radius: 100%;
    width: 2em;
    height: 2em;
    text-align: center;
  }

  #add-item-button:hover, #post-form-close:hover {
    cursor: pointer;
  }
</style>